<template>
  <ui-component-modal
    modalTitle="Add option"
    modalSize="large"
    :onClickCancel="onClickCancel"
    :onClickSave="saveCart"
    :showModal="showModal"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :savingSuccessMessage="'Data saved'"
    :savingErrorMessage="'Something went wrong'"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :disableSaveButton="newOptions.length === 0"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="columns">
        <div class="column">
          <div class="tabs is-boxed">
            <ul>
              <template v-for="(category, index) in filteredCategories">
                <li
                  :key="index"
                  v-if="category.NrOfOptions > 0"
                  @click="changeCategory(category.Id)"
                  :class="{ 'is-active': selectedCategory === category.Id }"
                >
                  <a>{{ getCategoryName(category.Id) }}</a>
                </li>
              </template>
            </ul>
          </div>

          <div>
            <ui-loader v-if="isOptionLoading === true" />

            <article
              v-for="(item, index) in displayOptions"
              :key="index"
              class="media"
            >
              <figure class="media-left">
                <p class="image is-32x32">
                  <img
                    key="image"
                    :src="
                      item.OptionImage
                        | getLocationImage(item.LocationId, '160')
                    "
                    class="image"
                  />
                </p>
              </figure>
              <div class="media-content">
                <div class="content">
                  <span>{{ item.OptionName }}</span>
                </div>
              </div>
              <div class="media-right">
                <button
                  @click="addOption(item)"
                  class="button is-small is-primary"
                >
                  <span class="icon is-small">
                    <font-awesome-icon
                      :icon="['fas', 'plus']"
                      style="color: white;"
                    />
                  </span>
                </button>
              </div>
            </article>
          </div>
        </div>
        <div class="column is-three-fifths">
          <div class="tabs is-boxed">
            <ul>
              <li
                @click="changeSpace(0)"
                :class="{ 'is-active': selectedSpace === 0 }"
              >
                <a>All</a>
              </li>
              <li
                v-for="(space, index) in cart.Spaces"
                :key="index"
                @click="changeSpace(space.SpaceId)"
                :class="{ 'is-active': selectedSpace === space.SpaceId }"
              >
                <a>{{ space.SpaceName }}</a>
              </li>
            </ul>
          </div>

          <message
            v-if="filteredSpaceOptions.length === 0"
            :message="'No options selected'"
          />

          <table
            v-if="filteredSpaceOptions.length > 0"
            class="table is-fullwidth is-narrow"
          >
            <thead>
              <tr>
                <th>Name</th>
                <th width="100" class="has-text-right">Time</th>
                <th width="100" class="has-text-right">Qty.</th>
                <th width="100" class="has-text-right">Price</th>
                <th width="30">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(option, index) in filteredSpaceOptions" :key="index">
                <td>
                  {{ option.OptionName }}
                </td>
                <td>
                  <div v-if="option.TimeSelectable > 0" class="select is-small">
                    <select v-model="option.SelectedTime">
                      <option :value="-1" :selected="true">None</option>
                      <option
                        v-for="timeSlot in timeSlots"
                        :key="timeSlot"
                        :value="timeSlot"
                        >{{ timeSlot | minutesToTime }}</option
                      >
                    </select>
                  </div>
                </td>
                <td>
                  <div class="field has-addons">
                    <p class="control">
                      <input
                        type="text"
                        v-model.number="option.Amount"
                        class="input is-small"
                      />
                    </p>
                    <p class="control">
                      <a class="button is-small is-static">X</a>
                    </p>
                  </div>
                </td>
                <td>
                  <div class="field has-addons">
                    <p class="control">
                      <a
                        class="button is-static is-small"
                        v-html="option.CurrencySymbol"
                      ></a>
                    </p>
                    <p class="control">
                      <input
                        type="text"
                        @change="updateCrcValue(option)"
                        @keyup="delay(updateCrcValue(option), 200)"
                        v-model.number="option.PricePerItem"
                        class="input is-small"
                      />
                    </p>
                  </div>
                </td>
                <td class="has-text-right">
                  <a @click="deleteOption(option)">
                    <span class="icon is-small has-text-danger">
                      <font-awesome-icon :icon="['fas', 'trash-alt']" />
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import cartProvider from '@/providers/cart'
import crcProvider from '@/providers/crc'
import optionProvider from '@/providers/option'

import Message from '@/components/UI/Message'

export default {
  components: {
    message: Message
  },

  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    onClickCancel: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isOptionLoading: false,
      isSavingError: false,
      isSavingSuccess: false,
      isSaving: false,
      locationOptions: [],
      mCart: null,
      newOptions: [],
      searchTerm: '',
      selectedCategory: 1,
      selectedSpace: 0
    }
  },

  computed: {
    ...mapState('cartStore', ['cart']),

    filteredItems() {
      let result = []
      result = this.locationOptions.filter(
        item => item.CategoryId === this.selectedCategory
      )
      return result
    },

    filteredCategories() {
      let self = this
      let categories = []

      for (let i = 0; i < self.locationOptions.length; i++) {
        if (
          categories.findIndex(
            c => c.Id === self.locationOptions[i].CategoryId
          ) === -1
        ) {
          categories.push({
            Id: self.locationOptions[i].CategoryId,
            Name: self.locationOptions[i].CategoryName,
            NrOfOptions: self.locationOptions.filter(
              o => o.CategoryId === self.locationOptions[i].CategoryId
            ).length
          })
        }
      }

      if (categories.length === 1) {
        self.selectedCategory = categories[0].Id
        self.changeCategory(self.selectedCategory)
      }

      return categories
    },

    displayOptions() {
      let result = []
      if (this.filteredItems.length > 0) {
        for (let i = 0; i < this.filteredItems.length; i++) {
          let optionIndex = this.newOptions.findIndex(
            o =>
              o.OptionId === this.filteredItems[i].OptionId &&
              o.SpaceId === this.selectedSpace
          )

          let cartOptionIndex = this.cart.Options.findIndex(
            o =>
              o.OptionId === this.filteredItems[i].OptionId &&
              o.SpaceId === this.selectedSpace
          )

          if (optionIndex === -1 && cartOptionIndex === -1) {
            result.push(this.filteredItems[i])
          }
        }
      }
      return result
    },

    filteredSpaceOptions() {
      let options = []

      if (this.newOptions.length > 0) {
        //for (let i = 0; i < this.newOptions.length; i++) {
        options = this.newOptions.filter(o => o.SpaceId === this.selectedSpace)
        //}
      }

      return options
    },

    resStart() {
      let minStartMinutes = 0
      if (this.cart && this.cart.Spaces.length > 0) {
        var lowest = Number.POSITIVE_INFINITY
        var tmp
        for (var i = this.cart.Spaces.length - 1; i >= 0; i--) {
          tmp = this.cart.Spaces[i].StartMinutes
          if (tmp < lowest) {
            lowest = tmp
          }
        }
        minStartMinutes = lowest
      }
      return minStartMinutes
    },

    resEnd() {
      let maxEndMinutes = 0
      if (this.cart && this.cart.Spaces.length > 0) {
        var highest = Number.NEGATIVE_INFINITY
        var tmp
        for (var i = this.cart.Spaces.length - 1; i >= 0; i--) {
          tmp = this.cart.Spaces[i].EndMinutes
          if (tmp > highest) {
            highest = tmp
          }
        }
        maxEndMinutes = highest
      }
      return maxEndMinutes
    },

    timeSlots() {
      let slots = []
      let min = this.resStart
      let max = this.resEnd
      let minutes = 30

      let i = min
      while (i <= max) {
        slots.push(i)
        i = i + minutes
      }

      return slots
    }
  },

  created() {
    this.mCart = JSON.parse(JSON.stringify(this.cart))
    this.getCartOptions()
  },

  methods: {
    ...mapMutations('cartStore', ['setCart']),

    changeCategory(tab) {
      this.selectedCategory = tab
    },

    changeSpace(spaceId) {
      this.selectedSpace = spaceId
    },

    getCategoryName(value) {
      let name = ''

      switch (value) {
        case 1:
          name = 'F&B'
          break
        case 2:
          name = 'AV'
          break
        case 3:
          name = 'Parking'
          break
        case 4:
          name = 'Other'
          break
      }

      return name
    },

    getCartOptions() {
      this.isOptionLoading = true

      optionProvider.methods
        .getCartOptions(this.cart.CartKey, 0, this.searchTerm)
        .then(response => {
          if (response.status === 200) {
            this.locationOptions = response.data
          }
        })
        .finally(() => {
          this.isOptionLoading = false
        })
    },

    nrOfOptionsInCategory(categoryId) {
      return this.locationOptions.filter(o => o.CategoryId === categoryId)
        .length
    },

    addOption(item) {
      let newOption = JSON.parse(JSON.stringify(item))
      newOption.SpaceId = this.selectedSpace
      this.newOptions.push(newOption)
    },

    deleteOption(option) {
      let optionIndex = this.newOptions.findIndex(
        o => o.OptionId === option.OptionId && o.SpaceId === option.SpaceId
      )
      if (optionIndex > -1) {
        this.newOptions.splice(optionIndex, 1)
      }
    },

    delay(callback, ms) {
      let self = this

      if (self.timer) {
        clearTimeout(self.timer)
      }
      self.timer = setTimeout(() => {
        callback.apply(self)
        clearTimeout(self.timer)
      }, ms || 0)
    },

    updateCrcValue(option) {
      let optionIndex = this.newOptions.findIndex(
        o => o.OptionId === option.OptionId && o.SpaceId === option.SpaceId
      )
      if (optionIndex > -1) {
        if (Number(option.PricePerItem) > 0) {
          crcProvider.methods
            .checkCrc(option.OptionId, option.PricePerItem)
            .then(response => {
              if (response.status === 200) {
                //this.mOption.Crc = response.data
                this.newOptions[optionIndex].Crc = response.data
              }
            })
        }
      }
    },

    saveCart() {
      let self = this
      self.isSaving = true

      for (let i = 0; i < self.newOptions.length; i++) {
        let newOption = this.newOptions[i]

        self.mCart.Options.push({
          CartId: Number(self.mCart.Id),
          OptionId: newOption.OptionId,
          CategoryId: newOption.CategoryId,
          Amount: newOption.Amount,
          CurrencyId: newOption.CurrencyId,
          PricePerItem: newOption.PricePerItem,
          PriceTotal: newOption.PriceTotal,
          Crc: newOption.Crc,
          TaxId: newOption.TaxId,
          TaxPercentage: newOption.TaxPercentage,
          Id: 0,
          ChangeAllInSet: newOption.ChangeAllInSet,
          IsPP: newOption.IsPP,
          IsPerHour: newOption.IsPerHour,
          SelectedTime: newOption.SelectedTime,
          SpaceId: newOption.SpaceId
        })
      }

      cartProvider.methods
        .updateCart(self.mCart)
        .then(response => {
          if (response.status === 200) {
            self.setCart(response.data)

            self.isSavingSuccess = true

            setTimeout(() => {
              self.onClickCancel()
            }, 1500)
          }
        })
        .catch(e => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    }
  }
}
</script>
